<template>
    <div class="event_config">
        <Aside 
            :showCalendar="false" 
            :page_name="pageName"
            activeType="listWeek" />
    </div>
</template>

<script>
import Aside from '@apps/Calendar/components/Aside.vue'
export default {
    props: {
        widget: {
            type: Object,
            required: true
        },
        closeSettingDrawer: {
            type: Function,
            default: () => {}
        }
    },
    components: {
        Aside
    },
    computed: {
        pageName() {
            return (this.widget.page_name || this.widget.id) || this.widget.widget.id
        }
    },
    methods: {
        saveConfig() {
            this.closeSettingDrawer()
        },
        resetConfig() {
            this.closeSettingDrawer()
        }
    }
}
</script>

<style lang="scss" scoped>
.event_config{
    &::v-deep{
        .calendars_list{
            padding: 0px;
        }
        .calendar_aside{
            width: 100%;
            border: 0px;
        }
        .calendar_aside__scroller{
            height: initial;
            overflow: initial;
        }
        .calendar_aside__toggle{
            display: none;
        }
    }
}
</style>